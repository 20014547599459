<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog || copy_dialog)}">
    <MDBCardBody>
	<MDBContainer fluid class="mt-3">
    <transition name="fade">
      <PageLoading v-if="PageLoading"></PageLoading>
    </transition>
    <MDBRow>
      <MDBCol class="mb-2 text-start" md="2">
        <MDBSelect v-model:options="language.items" :label="$t('COMMON.LANGUAGE')" v-model:selected="language.selected"></MDBSelect>
      </MDBCol>
      <MDBCol md="2">
        <MDBInput v-model="where.amy" />
      </MDBCol>
      <MDBCol md="2">
        <MDBInput v-model="where.jason" />
      </MDBCol>
      <MDBCol class="mb-2 text-end">
        <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>
        <MDBBtn color="info" @click="add_dialog = true">{{ $t("PRODUCT-LIST.ADD-ITEM") }}</MDBBtn>  
      </MDBCol>
    </MDBRow>
		<DataTable
			:header="headers"
			:tabledata="desserts"
			v-model:total="total"
			v-model:options="options"
			v-model:loading="loading"
		>
      <template v-slot:lang="{ item }">
        {{ $t("LANG." + item.lang) }}
      </template>
			<!-- 時間轉換 -->
      <template v-slot:created_at="{ item }">
        {{ Common.TimeFormat(item.created_at) }}
      </template>
      <template v-slot:status="{ item }">
        {{ $t("MEMBER.STATUS_" + item.status) }}
      </template>
      <template v-slot:members="{ item }">
        {{ item.members.length }}
      </template>
      <!-- 操作template -->
      <!-- <template v-slot:uuid="{ item }">
				<a @click="EditUUid = item.uuid" data-bs-toggle="tooltip" :title="$t('PRODUCT-LIST.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
					<MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
				</a>
				<a @click="DeleteAction(item.uuid)" data-bs-toggle="tooltip" :title="$t('PRODUCT-LIST.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
					<MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
				</a>
      </template> -->
      <template v-slot:copy="{ item }">
        <MDBBtn color="success" @click="CopyUUid = item.uuid" class="px-3 py-2">
          <MDBIcon icon="copy" iconStyle="fas" class="text-white"/>
        </MDBBtn>
      </template>
      <template v-slot:edit="{ item }">
        <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
          <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
        </MDBBtn>
      </template>
      <template v-slot:uuid="{ item }">
        <MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
          <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
        </MDBBtn>
      </template>
		</DataTable>
	</MDBContainer>
  </MDBCardBody>
  </MDBCard>
	<ProductForm v-model="add_dialog" action="insert"></ProductForm>
  <ProductForm v-model="edit_dialog" action="update" v-model:uuid="EditUUid"></ProductForm>
  <ProductForm v-model="copy_dialog" action="copy" v-model:uuid="CopyUUid"></ProductForm>
</template>

<style scoped>
  .sub-category {
    list-style: auto;
    text-align: start;
  }
  .sub-category li {
    border-bottom: 1px solid;
    width: fit-content;
  }
  .sub-div {
    display: inline-block;
    margin-right: 10px;
  }
</style>

<script>
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol, MDBSelect, MDBCard, MDBCardBody, MDBInput } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import ProductForm from '@/components/Form/Product/ProductForm';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import i18nService from "@/core/services/i18n.service.js";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';

export default {
  name: "ProductList",
  components: {
		DataTable,
		MDBContainer,
		MDBIcon,
		Breadcrumbs,
		ProductForm,
		MDBBtn,
    MDBRow,
    MDBCol,
    MDBSelect,
    MDBCard,
    MDBCardBody,
    PageLoading,
    MDBInput
  },
  setup() {
    const i18n = useI18n();
    const PageLoading = ref(true);
    const add_dialog = ref(false);
    const edit_dialog = ref(false);
    const copy_dialog = ref(false);
    const loading = ref(true);
    const total = ref(0);
    const Common = CommonService;
    const ProductCategory = reactive([]);
    const ProductTag = reactive([]);
    const EditUUid = ref("");
    const CopyUUid = ref("");
    const desserts = ref([]);
    const Commodity = reactive([]);
    const language = reactive({
      items: i18nService.languages.map(item => { item.selected = (item.lang == i18nService.getActiveLanguage()); return item;}),
      selected: ""
    });

    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });

    const where = reactive({
      amy: "",
      jason: "",
    });

    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no"
      },
      { text: i18n.t("COMMON.LANGUAGE"), value: "lang" },
      { text: i18n.t("COMMON.COMMODITYNUMBER"), value: "product_number" },
      { text: i18n.t("COMMON.NAME"), value: "name" },
      { text: i18n.t("COMMON.COMMODITYDESCRIPTION"), value: "description" },
      { text: i18n.t("COMMON.CREATE_AT"), value: "created_at" },
      { text: i18n.t("COMMON.COPY"), value: "copy", sortable: false ,class: "text-center",width:"50px"},
      { text: i18n.t("COMMON.EDIT"), value: "edit", sortable: false ,class: "text-center",width:"50px"},
      { text: i18n.t("COMMON.DELETE"), value: "uuid", sortable: false ,class: "text-center",width:"50px"}
    ]);

    const SelectedLanguage = function() {
      language.items.map(item => {
        if(item.lang == i18nService.getActiveLanguage()){
          item.selected = true;
        }else{
          item.selected = false;
        }
      });
    };


    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/Product",
          {
            params: Object.assign(CommonService.SortHandleObject(options),{lang:language.selected,type:1},where)
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

    const GetCategory = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/ProductCategory",
          {}
        ).then(response => {
          if (response.status == 200) {
            response.data.data.map(categroy => {
              categroy.children = categroy.sub;
              ProductCategory.push(categroy);
            });
            resolve(response);
          }
        });
      });
    };

    const GetCommodity = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/PackageCommodity",
          {
            params: Object.assign(CommonService.SortHandleObject({
              page: 1,
              itemsPerPage: -1,
              sortBy: ["created_at"],
              sortDesc: []
            }),{status:1})
          }
        ).then(response => {
          if (response.status == 200) {
            response.data.data.data.map(commodity => {
              Commodity.push(commodity);
            });
            resolve(response);
          }
        });
      });
    };

    const GetTag = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/ProductTag",
          {
            params: CommonService.SortHandleObject({
              page: 1,
              itemsPerPage: -1,
              sortBy: ["sort"],
              sortDesc: [],
              type: 1,
            })
          }
        ).then(response => {
          if (response.status == 200) {
            response.data.data.data.map(tag => {
              ProductTag.push(tag);
            });
            resolve(response);
          }
        });
      });
    };

    const DeleteAction = function(uuid) {
      CommonService.DeleteSwal().then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/Product/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                GetTable();
              }
            }
          );
        }
      });
    };

		return {
      PageLoading,
      add_dialog,
      edit_dialog,
      copy_dialog,
      loading,
      total,
      Common,
      ProductCategory,
      options,
      headers,
      desserts,
      EditUUid,
      CopyUUid,
      Commodity,
      language,
      SelectedLanguage,
      GetTable,
      GetCategory,
      GetCommodity,
      DeleteAction,
      ProductTag,
      GetTag,
      where
    };
  },
  mounted() {
    Promise.all([
      this.SelectedLanguage(),
      this.GetCategory(),
      this.GetCommodity(),
    ]).then(() => {
      Promise.all([
        this.$store.dispatch("ImageFolder"),
        this.GetTag(),
      ]).then(() => {
        this.PageLoading = false;
      });
    });
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    },
    "language.selected": function(){
      this.GetTable();
    }
  },
  provide () {
    return {
      GetTable: this.GetTable,
      ProductCategory: this.ProductCategory,
      Commodity: this.Commodity,
      ProductTag: this.ProductTag,
    }
  }
};
</script>