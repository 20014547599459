<template>
  <li >
    <div class="title">
      <i class="tree-btn" @click="active = !active" :class="{ 'fas fa-caret-right': (typeof data.children != 'undefined' && data.children.length != 0 && !active),'fas fa-caret-down':(typeof data.children != 'undefined' && data.children.length != 0 && active) }"></i>
      <div class="item">
        <input class="form-check-input" type="checkbox" v-bind:value="data.id" v-bind:id="uuid" v-model="input" :class="{ 'one': (CheckOne && !CheckAll ) }" @change="Change">
        <i class="fas fa-folder tree-btn me-2" v-if="icon"></i>
        <label class="form-check-label" v-bind:for="uuid">
          <slot name="name" :item="data">
            {{ data.name }}  
          </slot>
        </label>
      </div>
    </div>
    <ul class="sub" v-if="data.children" v-bind:data-f="uuid" :class="{ 'active' : active}">
      <template v-for="(item, k) in data.children" :key="k">
        <TreeSelect2Item :data="item" :icon="icon" v-model:selected="itemSelected" :open="open">
          <template v-slot:name="{ item }">
            <slot name="name" :item="item">
            </slot>
          </template>
        </TreeSelect2Item>  
      </template>
    </ul>
  </li>
</template>

<style scope>
  .form-check-input.one[type=checkbox]:after {
    display: block;
    transform: rotate(90deg) /*!rtl:ignore; */;
    border-width: .125rem;
    border-color: #fff;
    width: .375rem;
    height: .8125rem;
    border-style: solid;
    border-top: 0;
    border-left: 0 /*!rtl:ignore;;; */;
    border-bottom: 0;
    margin-left: .25rem;
    margin-top: -1px;
    background-color: transparent;
    content: "";
    position: absolute;
  }
  .form-check-input.one[type=checkbox] {
    background-image: none;
    background-color: #1266f1;
    border-color: #1266f1;
  }
</style>

<script>
import { computed, reactive, ref } from "vue";
import { uuid } from "vue-uuid";
export default {
  name: "TreeSelect2Item",
  props: {
    data : {
      type: Object,
      default: function() {
        return {};
      } 
    },
    selected: {
      type: Array,
      default: function(){
        return [];
      }
    },
    icon: {
      type: Boolean,
      default: true
    },
    uuid: {
      type: String,
      default: function() {
        return uuid.v1();
      }
    },
    open:{
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {

    const parent = ref(props.data.id);
    const children = reactive((typeof props.data.children != 'undefined')?props.data.children.map(item => item.id):[]);
    const active = ref(false);
    const input = ref(false);

    const itemSelected = computed({
      get: () => props.selected,
      set: val => emit("update:selected", val),
    });

    const CheckAll = ref(false);
    const CheckOne = ref(false);

    const Change = function(event) {
      let check = event.target.checked;
      let ul = document.querySelector(`[data-f="${event.target.id}"]`);
      let value = event.target.value;
      if(check) {
        if(itemSelected.value.indexOf(value) <0 ){
          itemSelected.value.push(value);  
        }
      }else{
        itemSelected.value.splice(itemSelected.value.indexOf(value),1);
      }
      children.map( item => {
        let selectitem = ul.querySelector(`input[value="${item}"]`);
        selectitem.checked = !check;
        selectitem.click();
      });
    }

    return {
      input,
      parent,
      itemSelected,
      children,
      CheckAll,
      CheckOne,
      Change,
      active
    }
  },
  watch:{
    itemSelected: {
      handler() {
        let checkbox = document.getElementById(`${this.uuid}`);
        if(checkbox != null){
          checkbox.checked = (this.itemSelected.indexOf(this.data.id.toString()) >= 0);
        }
        let checked = this.children.map(item => this.itemSelected.indexOf(item.toString()) >= 0).filter(item => item === true);
        this.CheckAll = (checked.length > 0 && (this.children.length == checked.length));
        this.CheckOne = false;
        if(this.CheckAll) {
          if(this.itemSelected.indexOf(this.parent.toString()) < 0 ){
            this.itemSelected.push(this.parent.toString());  
          }
        }else{
          if(this.itemSelected.indexOf(this.parent.toString()) >= 0 && this.children.length >0){
            this.itemSelected.splice(this.itemSelected.indexOf(this.parent.toString()),1);
          }
          let ul = document.querySelector(`[data-f="${this.uuid}"]`);
          if(ul != null) {
            let all = ul.querySelectorAll('input[type="checkbox"]');
            if([...all].map(item => item.checked === true).filter(item => item === true).length > 0) {
              this.CheckOne = true;
            }else{
              this.CheckOne = false;
            }
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    this.active = this.open;
  }
};
</script>