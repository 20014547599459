<template>
  <div class="tree">
    <ul class="main" :id="uuid">
      <template v-for="(item, k) in items" :key="k">
        <TreeSelect2Item :data="item" :icon="icon" v-model:selected="itemSelected" :open="open">
          <template v-slot:name="{ item }">
            <slot name="name" :item="item">
            </slot>
          </template>
        </TreeSelect2Item>
      </template>
    </ul>
    </div>
</template>

<style scope>
  .tree ul.main{
    /*margin: 0;*/
    padding: 0;
  }
  .tree li {
    list-style: none;
  }
  .tree .sub {
    display: none;
  }
  .tree ul.sub.active{
    display: block;
  }
  .tree .item {
    display: flex;
  }
  .tree .title {
    display: flex;
  }
  .tree .tree-btn {
    cursor: pointer;
    width: 25px;
    height: 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>

<script>
import TreeSelect2Item from '@/components/TreeSelect2Item';
import { computed } from "vue";
import { uuid } from "vue-uuid";
export default {
  name: "TreeSelect2",
  components: {
    TreeSelect2Item
  },
  props: {
    items: {
      type: [Array,Object],
      default: function(){
        return {};
      }
    },
    selected: {
      type: Array,
      default: function(){
        return [];
      }
    },
    icon: {
      type: Boolean,
      default: true
    },
    open: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: function() {
        return uuid.v1();
      }
    }
  },
  setup(props, { emit }) {

    const itemSelected = computed({
      get: () => props.selected,
      set: val => emit("update:selected", val),
    });

    return {
      itemSelected
    }
  },
  watch: {
    items: {
      handler:function() {
        setTimeout(() => {
          this.itemSelected.map(item => {
            let checkbox = document.getElementById(`${this.uuid}`).querySelector(`input[value="${item}"]`);
            if(checkbox != null){
              checkbox.checked = (this.itemSelected.indexOf(item.toString()) >= 0);
            }
            this.itemSelected.push(this.uuid);
            this.itemSelected.splice(this.itemSelected.indexOf(this.uuid),1);
          })
        }, 100);
      },
      deep:true
    }
  }
};
</script>